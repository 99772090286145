import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Notification from '@/modules/common/services/notification.service'
import { DataTableHeader } from 'vuetify'

@Component({
  name: 'GtrBadgesPrinterView',
  computed: {
    ...mapState('company', ['printer', 'companies'])
  }
})
export default class GtrDetailsAdminUserView extends GtrSuper {
  printer!: any;

  data () {
    return {
      user: null,
      user_uuid: this.$route.params.user_uuid,
      _companies: []
    }
  }

  get kioskURL () {
    return this.printer.admin_url.substring(0, this.printer.admin_url.length - 12)
  }

  get softwareVersion () {
    return this.printer.stats?.software_version ?? 'Unknown'
  }

  get printStats () {
    return this.printer.stats?.prints_by_hour_by_day ?? []
  }

  get printStatsDeviceKiosks () {
    return this.printer.stats?.devices?.kiosks ?? []
  }

  get printStatsDevicePrinters () {
    return this.printer.stats?.devices?.printers ?? []
  }

  get printStatsHeaders () {
    if (this.printStats.length > 0) {
      const keys: string[] = Object.keys(this.printStats[0])
      return keys.map((key: any): DataTableHeader => ({ text: key, value: key }))
    } else {
      return []
    }
  }

  get printStatsDeviceKioskHeaders () {
    if (this.printStatsDeviceKiosks.length > 0) {
      const keys: string[] = Object.keys(this.printStatsDeviceKiosks[0])
      return keys.map((key: any): DataTableHeader => ({ text: key, value: key }))
    } else {
      return []
    }
  }

  get printStatsDevicePrinterHeaders () {
    if (this.printStatsDevicePrinters.length > 0) {
      const keys: string[] = Object.keys(this.printStatsDevicePrinters[0])
      return keys.map((key: any): DataTableHeader => ({ text: key, value: key }))
    } else {
      return []
    }
  }

  @Watch('companies')
  onCompaniesChange (payload: any) {
    this.$data._companies = []
    if (payload.length > 0) {
      payload.map((company: any) => {
        this.$data._companies.push({
          value: company.uuid,
          text: company.name
        })
      })
      this.$data._companies.sort((a, b) => a.text > b.text)
    }
  }

  @Watch('printer')
  onPrinterChange (payload: any) {
    this.$data.printer = payload
  }

  async mounted () {
    await this.fetchCompanies()
    await this.fetchPrinter(this.$route.params.uuid)
  }

  async impersonateUser (user: any) {
    try {
      this.$data.loading = true
      await this.$store.dispatch('security/impersonateUser', user.uuid)
      this.$router.go(0)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async deletePrinter () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('company/deletePrinter', { printer_uuid: this.$route.params.uuid })
      Container.get(Notification).success('Successfully deleted printer')
      this.$router.push({ name: 'level-one.printers' })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchPrinter (uuid) {
    try {
      this.$data.loading = true
      await this.$store.dispatch('company/fetchPrinter', uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchCompanies () {
    try {
      this.$data.loading = true
      this.$store.commit('company/SET_COMPANIES', [])
      await this.$store.dispatch('company/fetchCompanies')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async saveMachine () {
    try {
      const payload = {
        uuid: this.$route.params.uuid,
        data: { company_uuid: this.$data.printer.company_uuid }
      }
      await this.$store.dispatch('company/saveMachine', payload)
      Container.get(Notification).success('Saved')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
